<template>
	<div style="margin: 30px 15px 15px 15px;">
		<div style="text-align: center;">
			<div class="title">关于三个零 | ABOUT US</div>
		</div>
		
		<div style="padding: 20px 0;">
			我们倡导“叁零”健康白酒理念：<br/>
中国白酒（Chinese Baijiu）全产业链、全生命周期遵守“3个0”原则-“0兑0加0塑”，即“0食用酒精勾兑、0化学添加剂、0塑化剂”，倡导健康生活，保护你我健康。<br/>
我们设立“叁零”健康守护人制度，开展健康白酒理念的导入和宣传，引领中国白酒走向世界。<br/>
我们致力于为会员和消费者筛选符合“3个0”标准的优质酒源、做好品控、致力于源头直采、向各位会员提供高质、平价、健康、放心的酒品，免费提供个性化品牌定制服务。<br/>
我们致力于推广可应用于白酒行业（酿造、储运、包装、认证、调制、消费等全过程）的新材料、新工艺和新技术，赋能传统产业，倡导白酒产业的标准化、智能化、生态有机化和低碳环保化。<br/>
欢迎您加盟叁零薈！让我们携手努力，共同守护健康！</div>
		<div style="text-align: center;">
			<div class="title">三个零酒品 | PRODUCTS</div>
		</div>
		<div style="text-align: center;padding: 20px 0;">
			<img src="../../../public/static/product.jpg" style="width: 100%;"/>
		</div>
		<div style="padding-bottom: 20px;">
			叁零薈为会员提供以下系列酒品，会员按级别分别享有6-9折优惠，均可提供个性化品牌定制，对于酒类经销商等集采会员，批发价另议。
		</div>
		<div class="title2">
			<div class="left">1</div>
			<div class="right">“天”款顶级极品</div>
		</div>
		<div style="padding: 20px 0;">
			自然茅香、极品老酒，对标15年飞天，专用于高端接待/高档礼品/高净值会员口粮酒。<br/>
		会员原价￥2999元/500ml。
		</div>
		<div class="title2">
			<div class="left">2</div>
			<div class="right">“人”款轻奢珍品</div>
		</div>
		<div style="padding: 20px 0;">
		大曲稇沙、超高品质，用于会员口粮酒/重要商务接待。<br/>会员原价￥799元/500ml。
		</div>
		<div style="text-align: center;">
			<div class="title">三个零会员 | MEMBERSHIP</div>
		</div>
		<div style="padding: 20px 0;">叁零薈为半公益化的民间组织，我们的会员以科学家、专家学者、高级顾问、企业家、社会名流、行业精英、知名人士和高净值人士为主体会员，依托校友会、商会、协会及论坛等高端圈层，倡导和推广“3个0”健康白酒理念。<br/>
	我们的会员分为创始人会员、钻石级会员、铂金级会员、黄金级会员、白银级会员，分别享有不同的优惠权利和积分奖励政策。某些高端、小众、限量版酒品和封坛、众筹等投资机会，仅限于会员内分享。
		</div>
		<div style="border-top:#e0e0e0 1px solid;text-align: center;margin-top: 10px; padding-top: 10px;">
			<a href="http://beian.miit.gov.cn/" target="_blank">沪ICP备15038625号-3</a>
		</div>
	</div>

</template>

<script>
export default {
	name: 'index',
	components: {
	},
	data() {
		return {
			title:"三个零"
		}
	},
	created() {
		
	},
	methods: {
	
	}
}
</script>

<style>
.title{background-color:#fad182;border-radius:30px;padding:5px 20px}
.title,.title2{display:inline-block;font-weight:700}.title2{color:#fff;background-color:#000;border-radius:4px}.title2 .left,.title2 .right{display:inline-block;padding:5px 10px}.title2 .right{background-color:#e6141d;border-radius:0 4px 4px 0}

</style>
